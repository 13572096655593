import React from 'react';
import { Router } from '@reach/router';
import Admin from 'views/Admin';
import Home from 'views/Home';
import Checkout from 'views/Checkout';
import ThankYou from 'views/Checkout/subviews/ThankYou';

import { HEADER_HEIGHT } from 'theme/constants';

const Routes = () => (
    <>
        <div className="routes-container">
            <Router>
                <Home path="/" />
                <Checkout path="/checkout/:invoiceId" />
                <ThankYou path="/checkout/thankyou/:invoiceId" />
                <Admin path="admin/*" />
            </Router>
        </div>
        <style jsx>{`
            .routes-container {
                padding-top: ${HEADER_HEIGHT}px;
            }
        `}</style>
    </>
);

export default Routes;
