import React from 'react';
import { auth, googleAuthProvider } from 'firebase-instance';

export default function AdminSignIn() {
	const signIn = async () => {
		await auth.signInWithPopup(googleAuthProvider);
	};

	return (
		<div className="container pt-md pb-md">
			<h2 className="mb-md">Please sign in to access admin features!</h2>
			<button className="btn-barney" onClick={signIn}>Sign In with Google</button>
		</div>
	);
}
