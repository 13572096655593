import React from 'react';

import icon from 'images/podcastguru-logo-icon.svg';
import normal from 'images/podcastguru-logo.svg';
import dark from 'images/podcastguru-logo-dark.svg';

const LOGO_SRC = {
	icon, normal, dark
};

const Logo = ({ height = 'auto', type = 'normal', className }) => (
	<img
		className={className}
		height={height}
		src={LOGO_SRC[type]}
		alt="Podcast Guru Logo"
	/>
);

export default Logo;
