import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';

import Input from 'components/Input';

import SelectGenre from './parts/SelectGenre';
import SelectGeos from './parts/SelectGeos';
import SelectDate from './parts/SelectDate';

import { colors, spacing } from 'theme';

import { SERVICE_BASE_URL, API_KEY } from 'constants.js';

const CreateInvoice = () => {
    const [successData, setSuccessData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [itunesId, setItunesId] = useState(null);
    const [genreId, setGenreId] = useState(null);
    const [lstGeos, setLstGeos] = useState(null);
    const [tsStartDate, setTsStartDate] = useState(new Date().getTime() / 1000);
    const [numDays, setNumDays] = useState(null);
    const [priceUsd, setPriceUsd] = useState(null);
    const [contactName, setContactName] = useState(null);
    const [contactEmail, setContactEmail] = useState(null);

    useEffect(() => {
        setError(null);
    }, [
        itunesId,
        genreId,
        lstGeos,
        tsStartDate,
        numDays,
        priceUsd,
        contactName,
        contactEmail,
    ]);

    const createInvoice = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                `${SERVICE_BASE_URL}/gcf_create_placement_invoice?api_key=${API_KEY}`,
                {
                    method: 'post',
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        itunesId,
                        genreId,
                        lstGeos,
                        tsStartDate,
                        numDays,
                        priceUsd,
                        contactName,
                        contactEmail,
                    }),
                }
            );
            if (response.ok) {
                const data = await response.json();
                setSuccessData(data);
                console.log(data);
            } else {
                const error = await response.json();
                setError(error);
            }
        } catch (error) {
            setError('Unexpected error. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const goToCheckout = () => {
        const { invoiceId } = successData;
        navigate(`/checkout/${invoiceId}`);
    };

    const clearForm = () => {
        setItunesId(null);
        setGenreId(null);
        setLstGeos(null);
        setTsStartDate(new Date().getTime() / 1000);
        setNumDays(null);
        setPriceUsd(null);
        setContactName(null);
        setContactEmail(null);
        setSuccessData(null);
    };

    if (successData)
        return (
            <div className="container pt-md pb-md">
                <p className="success-text">
                    Successfully created invoice <b>{successData.invoiceId}</b>
                </p>
                <div className="mt-md">
                    <button className="btn-barney mr-sm" onClick={goToCheckout}>
                        Go to Checkout
                    </button>
                    <button onClick={clearForm}>Create another invoice</button>
                </div>
            </div>
        );

    return (
        <>
            <div className="container pt-md pb-md">
                <h1 className="mb-md">Create Invoice</h1>
                <form onSubmit={(e) => e.preventDefault()}>
                    {error && <div className="form-error">{error}</div>}
                    <Input
                        label="iTunes ID"
                        type="number"
                        value={itunesId}
                        onChange={setItunesId}
                    ></Input>
                    <SelectGenre onChange={setGenreId} />
                    <SelectGeos onChange={setLstGeos} />
                    <SelectDate onSelectDate={setTsStartDate} />
                    <Input
                        label="Number of days"
                        type="number"
                        value={numDays}
                        onChange={setNumDays}
                    ></Input>
                    <Input
                        label="Price (USD)"
                        type="number"
                        value={priceUsd}
                        onChange={setPriceUsd}
                    ></Input>
                    <Input
                        label="Contact name"
                        value={contactName}
                        onChange={setContactName}
                    ></Input>
                    <Input
                        label="Contact email"
                        value={contactEmail}
                        onChange={setContactEmail}
                    ></Input>
                    <button
                        className="btn-barney"
                        onClick={createInvoice}
                        disabled={loading || error}
                    >
                        Create Invoice
                    </button>
                </form>
            </div>
            <style jsx>{`
                .form-error {
                    padding: ${spacing.sm} ${spacing.md};
                    background-color: ${colors.red};
                    color: white;
                    border-radius: 100px;
                    margin-bottom: ${spacing.md};
                }
                .success-text {
                    font-size: 16px;
                }
            `}</style>
        </>
    );
};

export default CreateInvoice;
