export default {
	black: '#000000',
	greyishBrown: '#4a4a4a',
	brownGrey: '#999999',
	paleBlue: '#e2e3e4',
	iceBlue: '#e5f8fd',
	white: '#ffffff',
	black87: 'rgba(0, 0, 0, 0.87)',
	black54: 'rgba(0, 0, 0, 0.54)',
	white54: 'rgba(255, 255, 255, 0.54)',
	purple: '#7b1fa2',
	barney: '#9c27b0',
	lavenderPink: '#ce93d8',
	purplyPink: '#ea80fc',
	turquoise: '#00bfa5',
	tiffanyBlue: '#64ffda',
	red: '#db0101',
	orangeYellow: '#ff9e00',
};
