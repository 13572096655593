import React, { useState, useEffect } from 'react';
import { auth } from 'firebase-instance';

export const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
	const [state, setState] = useState(() => {
		const user = auth.currentUser;
		return { initializing: !user, user };
	});

	const signOut = () => {
		auth.signOut();
	};

	const onChange = user => {
		setState({ initializing: false, user });
	};

	useEffect(() => {
		// listen for auth state changes
		const unsubscribe = auth.onAuthStateChanged(onChange);
		// unsubscribe to the listener when unmounting
		return () => unsubscribe();
	}, []);

	const value = {
		...state,
		signOut,
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
