import { global } from 'styled-jsx/css';

export default global`
    .pt-xs {
        padding-top: 4px;
    }
    .pt-sm {
        padding-top: 8px;
    }
    .pt-md {
        padding-top: 16px;
    }
    .pt-lg {
        padding-top: 32px;
    }
    .pt-xl {
        padding-top: 42px;
    }
    .pt-xxl {
        padding-top: 64px;
    }
    .pb-xs {
        padding-bottom: 4px;
    }
    .pb-sm {
        padding-bottom: 8px;
    }
    .pb-md {
        padding-bottom: 16px;
    }
    .pb-lg {
        padding-bottom: 32px;
    }
    .pb-xl {
        padding-bottom: 42px;
    }
    .pb-xxl {
        padding-bottom: 64px;
    }
    .pl-xs {
        padding-left: 4px;
    }
    .pl-sm {
        padding-left: 8px;
    }
    .pl-md {
        padding-left: 16px;
    }
    .pl-lg {
        padding-left: 32px;
    }
    .pl-xl {
        padding-left: 42px;
    }
    .pl-xxl {
        padding-left: 64px;
    }
    .pr-xs {
        padding-right: 4px;
    }
    .pr-sm {
        padding-right: 8px;
    }
    .pr-md {
        padding-right: 16px;
    }
    .pr-lg {
        padding-right: 32px;
    }
    .pr-xl {
        padding-right: 42px;
    }
    .pr-xxl {
        padding-right: 64px;
    }
    .mt-xs {
        margin-top: 4px;
    }
    .mt-sm {
        margin-top: 8px;
    }
    .mt-md {
        margin-top: 16px;
    }
    .mt-lg {
        margin-top: 32px;
    }
    .mt-xl {
        margin-top: 42px;
    }
    .mt-xxl {
        margin-top: 64px;
    }
    .mb-xs {
        margin-bottom: 4px;
    }
    .mb-sm {
        margin-bottom: 8px;
    }
    .mb-md {
        margin-bottom: 16px;
    }
    .mb-lg {
        margin-bottom: 32px;
    }
    .mb-xl {
        margin-bottom: 42px;
    }
    .mb-xxl {
        margin-bottom: 64px;
    }
    .ml-xs {
        margin-left: 4px;
    }
    .ml-sm {
        margin-left: 8px;
    }
    .ml-md {
        margin-left: 16px;
    }
    .ml-lg {
        margin-left: 32px;
    }
    .ml-xl {
        margin-left: 42px;
    }
    .ml-xxl {
        margin-left: 64px;
    }
    .mr-xs {
        margin-right: 4px;
    }
    .mr-sm {
        margin-right: 8px;
    }
    .mr-md {
        margin-right: 16px;
    }
    .mr-lg {
        margin-right: 32px;
    }
    .mr-xl {
        margin-right: 42px;
    }
    .mr-xxl {
        margin-right: 64px;
    }
`;
