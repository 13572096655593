import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyBQpEoxhSiqHZ-fcZZKU50CNUrihOkyFdM',
	authDomain: 'podcastguru-217d6.firebaseapp.com',
	databaseURL: 'https://podcastguru-217d6.firebaseio.com',
	projectId: 'podcastguru-217d6',
	storageBucket: 'podcastguru-217d6.appspot.com',
	messagingSenderId: '987414865989',
	appId: '1:987414865989:web:2b6ddde7f66c0c90099b2c',
	measurementId: 'G-XT80J365T1',
};

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

//const auth = app.auth();
const db = firebase.firestore();
const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export {
	db,
	auth,
	googleAuthProvider,
};