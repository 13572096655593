import React from "react";
import { spacing } from 'theme';

const Input = ({ label, type="text", onChange, underInputLabel, error, success }) => (
    <>
        <div
            className={`input-container ${error ? "error" : ""} ${
                success ? "success" : ""
            }`}
        >
            <label>{label}</label>
            <input type={type} onChange={e => onChange(e.target.value)} />
            {underInputLabel && (
                <label className="under-input-label">{underInputLabel}</label>
            )}
        </div>
        <style jsx global>{`
            label {
                display: block;
                margin-bottom: 4px;
            }

            input {
                display: block;
                width: 100%;
                height: 40px;
                font-size: 16px;
                outline: none;
				padding: 0 ${spacing.md};
            }

            .input-container {
                margin-bottom: 16px;
            }

            .error .under-input-label {
                color: red;
            }
            .error input {
                border-color: red;
            }

            .success .under-input-label {
                color: green;
            }
            .success input {
                border-color: green;
            }
        `}</style>
    </>
);

export default Input;
