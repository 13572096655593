import React from 'react';

// TESTING- http://localhost:3000/checkout/thankyou/5fcd681a

// TODO - test from service

import ProductDisplay from '../parts/ProductDisplay';

const ThankYou = ({ invoiceId }) => (
    <div className="container pt-md">
        <h2 className="mb-lg">Thank You!</h2>
        <ProductDisplay invoiceId={invoiceId} />
    </div>
);

export default ThankYou;
