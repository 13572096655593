import React from 'react';

import { colors, spacing } from 'theme';

const statusColors = {
    paid: colors.turquoise,
    unpaid: colors.red,
}

const PaymentStatus = ({ status }) => (
    <>
        <div className="payment-status">
            Status:<span>{status}</span>
        </div>
        <style jsx>{`
            .payment-status {
                font-size: 16px;
            }
            span {
                color: white;
                margin-left: ${spacing.sm};
                border-radius: 100px;
                padding: ${spacing.xs} ${spacing.md};
                background-color: ${statusColors[status.toLowerCase()]};
            }
        `}</style>
    </>
);

export default PaymentStatus;
