import React from 'react';
import { useLocation, navigate } from '@reach/router';

import Tabs from 'components/Tabs';

const tabs = [
	{ value: '/admin', label: 'Admin Home' },
	{ value: '/admin/create-invoice', label: 'Create Invoice' },
];

const AdminMenu = () => {
    const { pathname } = useLocation();
    return (
		<div className="container pt-md">
			<Tabs tabs={tabs} activeTab={pathname} onTabSelect={tab => navigate(tab)} />
		</div>
    );
};

export default AdminMenu;
