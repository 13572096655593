import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { db } from 'firebase-instance';

const SelectGenre = ({ onChange }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [genres, setGenres] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await db
                    .collection('lookup_tables')
                    .doc('itunes_genre_codes')
                    .get();
                if (response.exists) {
                    const data = response.data();
                    const options = Object.keys(data).map((key) => ({
                        value: data[key],
                        label: key,
                    }));
                    setGenres(options);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <div className="input-container">
            <label>Genre</label>
            <Select
                options={genres}
                isLoading={isLoading}
                onChange={(option) => onChange(option.value)}
            />
        </div>
    );
};

export default SelectGenre;
