import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const SelectDate = ({ onSelectDate }) => {
    const [date, setDate] = useState(new Date());

    const handleChange = date => {
        // ensure we are using a UTC aligned timestamp and strip any local time information
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
        setDate(utcDate);
        onSelectDate(utcDate.getTime() / 1000);
    };

    return (
        <div className="input-container">
            <label>Start date</label>
            <DatePicker selected={date} onChange={handleChange} />
        </div>
    );
};

export default SelectDate;