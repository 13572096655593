import React from 'react';
import useAuth from 'hooks/useAuth';

const SignOutButton = () => {
    const { user, initializing, signOut } = useAuth();

    if (!initializing && user) {
        return (
            <button className="sign-out-button btn-sm" onClick={signOut}>
                Sign Out
            </button>
        );
    }

    return null;
};

export default SignOutButton;
