import React, { Fragment } from 'react';
import { colors, spacing } from 'theme';

const Tab = ({ tab, activeTab, onTabSelect, children }) => (
	<Fragment>
		<div className={`tab${tab === activeTab ? ' active' : ''}`} onClick={() => onTabSelect(tab)}>
			<div className="tab-inner">{children}</div>
		</div>
		<style jsx>{`
			.tab {
				font-family: Montserrat;
				cursor: pointer;
				height: 100%;
				display: flex;
				color: ${colors.black54};
				margin-right: ${spacing.md};
			}
			.tab-inner {
				height: 100%;
				display: flex;
				align-items: center;
				text-transform: uppercase;
				font-weight: 500;
				padding-bottom: 11px;
			}
			.tab.active .tab-inner, .tab:hover .tab-inner{
				font-weight: bold;
				color: ${colors.barney};
				box-shadow: inset 0 -3px 0px 0px ${colors.barney};
			}
		`}</style>
	</Fragment>
);

export default Tab;
