import React from 'react';
import Select from 'react-select';

const options = [
    { value: 'US,GB,NZ,AU,CA,IE,MT', label: 'All English Speaking' },
    { value: 'US,CA', label: 'North America' },
    {
        value:
            'AT,BE,BG,HR,CY,CZ,DK,DNK,EE,FI,FR,DE,GR,IE,IT,LV,LT,LU,MT,NL,PO,PT,RO,SK,SI,ES,SE',
        label: 'EU States',
    },
];

const SelectGeos = ({ onChange }) => (
    <div className="input-container">
        <label>Regions</label>
        <Select
            options={options}
            onChange={(option) => onChange(option.value)}
        />
    </div>
);

export default SelectGeos;
