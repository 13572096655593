import React, { useState, useEffect } from 'react';

import { SERVICE_BASE_URL, API_KEY } from 'constants.js';

import PaymentStatus from './PaymentStatus';

const ProductDisplay = ({ invoiceId, onCheckout }) => {
    const [invoiceData, setInvoiceData] = useState();

    useEffect(() => {
        // set the page title
        document.title = `Checkout - Invoice ${invoiceId}`;

        (async () => {
            const response = await fetch(
                `${SERVICE_BASE_URL}/gcf_get_podcast_placement_invoice?api_key=${API_KEY}&invoice_id=${invoiceId}`
            );
            if (response.ok) {
                const data = await response.json();
                setInvoiceData(data);
            } else {
                // TODO - log the error on a remote logging service (loki once we set it up and figure it out)
                console.log('ERROR');
            }
        })();
    }, [invoiceId]);


    if (!invoiceData) return null;

    // TODO, add some logic so this causes an error if the timestamp is in the past
    const startDate = new Date(invoiceData.startTimestamp * 1000);
    const endDate = new Date(
        startDate.getTime() + invoiceData.numDays * 86400000
    );

    // Use Intl.DateTimeFormat to format dates with time and UTC timezone
    const formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        weekday: 'short',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        timeZone: 'UTC'
    });

    const startDateUTC = formatter.format(startDate);
    const endDateUTC = formatter.format(endDate);

    return (
        <div>
            <div className="product mb-md">
                <div className="description">
                    <h3 className="mb-md">Featured Placement</h3>
                    <h5>
                        Contact: {invoiceData.contactName} /{' '}
                        {invoiceData.contactEmail}{' '}
                    </h5>
                    <h5>Podcast Name: {invoiceData.collectionName} ({invoiceData.itunesId})</h5>
                    <h5>Genre: {invoiceData.genreName} ({invoiceData.genreId})</h5>
                    <h5>Geos: {invoiceData.geos.join()}</h5>
                    <h5>Number of Days: {invoiceData.numDays}</h5>
                    <h5>Start Date: {startDateUTC}</h5>
                    <h5>End Date: {endDateUTC}</h5>
                    <h5>Price: ${invoiceData.priceUsd}</h5>
                </div>
            </div>
            <PaymentStatus status={invoiceData.paymentStatus} />
            {onCheckout && invoiceData.paymentStatus.toLowerCase() === 'unpaid' && (
                <button
                    id="checkout-button"
                    className="btn-barney mt-md"
                    role="link"
                    onClick={onCheckout}
                >
                    Checkout
                </button>
            )}
        </div>
    );
};

export default ProductDisplay;
