import React, { Fragment } from 'react';
import { Link, useLocation } from '@reach/router';
import { HEADER_HEIGHT } from 'theme/constants';
import { spacing, breakpoints } from 'theme';

import Logo from 'components/Logo';
import SignOutButton from 'components/SignOutButton';

const Header = () => {
	const { pathname } = useLocation();
	return (
		<Fragment>
			<div className="header-wrapper">
				<div className="container h-100">
					<div className="header-wrapper-inner">
						<div className="header-left">
							<Link to="/" className="logo-desktop mr-lg">
								<Logo height="64px" />
							</Link>
							<Link to="/" className="logo-mobile mr-md">
								<Logo height="51px" type="icon" />
							</Link>
							<Link to="/" className={`header-link mr-lg ${pathname === '/' && 'active'}`}>
								<span>Home</span>
							</Link>
							<Link to="/admin" className={`header-link ${pathname.includes('/admin') && 'active'}`}>
								<span>Admin</span>
							</Link>
						</div>
						<div className="header-right">
							<SignOutButton />
						</div>
					</div>
				</div>
			</div>

			{/* Styles */}
			<style jsx>{`
				.header-wrapper {
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					height: ${HEADER_HEIGHT}px;
					background-color: rgba(0, 0, 0, 0.54);
					z-index: 10;
				}
				.header-wrapper-inner {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 100%;
				}
				.header-right,
				.header-left {
					display: flex;
				}
				.menu-toggle {
					margin-right: ${spacing.md};
					cursor: pointer;
				}
				.google-play-badge {
					height: 64px;
				}
				:global(.logo-desktop) {
					display: none;
				}
				:global(.header-link) {
					display: flex;
					align-items: center;
				}
				:global(.header-link span) {
					font-family: Montserrat;
					font-size: 12px;
					font-weight: bold;
					color: white;
				}
				:global(.header-link.active) {
					opacity: 0.6;
				}
				@media (min-width: ${breakpoints.sm}) {
					:global(.logo-desktop) {
						display: block;
					}
					:global(.logo-mobile) {
						display: none;
					}
				}
			`}</style>
			<style jsx global>{``}</style>
		</Fragment>
	);
};

export default Header;
