import React from 'react';
import { LocationProvider } from '@reach/router';

//Providers
import AuthProvider from 'providers/AuthProvider';

//Routes
import Routes from 'routes';

import Header from 'components/Header';

import { colors, spacing, breakpoints } from 'theme';
import styles from 'theme/styles';

const App = () => (
    <>
        <AuthProvider>
            <LocationProvider>
                <Header />
                <Routes />
            </LocationProvider>
        </AuthProvider>

        <style jsx global>{`
            * {
                box-sizing: border-box;
            }

            html,
            body {
                width: 100%;
                height: 100%;
                margin: 0;
            }

            html {
                color: ${colors.greyishBrown};
                font-family: 'Roboto';
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            .min-page-height {
                min-height: 100vh;
            }
            .container {
                margin: auto;
                max-width: 1200px;
                padding: 0 ${spacing.md};
            }
            @media (min-width: ${breakpoints.sm}) {
                .container {
                    padding: 0 ${spacing.xl};
                }
            }

            /* Fonts */
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: ${colors.black87};
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                margin: 0;
            }
            h1 {
                font-size: 36px;
            }
            h2 {
                font-size: 30px;
            }
            h3 {
                font-size: 24px;
            }
            h4 {
                font-size: 20px;
            }

            a {
                text-decoration: none;
                color: ${colors.barney};
            }
            a.no-style {
                color: ${colors.greyishBrown};
            }

            a img {
                display: block;
            }

            button {
                display: inline-block;
                border-radius: 21px;
                color: ${colors.greyishBrown};
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
                border: none;
                height: 42px;
                padding: 0 ${spacing.md};
                background-color: white;
                white-space: nowrap;
                font-family: Montserrat;
                cursor: pointer;
                transition: opacity 200ms linear;
            }
            button span {
                font-size: 12px;
                vertical-align: middle;
            }
            button img {
                vertical-align: middle;
            }
            button:disabled {
                opacity: 0.5;
            }
            .btn-barney {
                background-color: ${colors.barney};
                color: white;
            }
            .btn-text {
                box-shadow: none;
                color: ${colors.black54};
                text-transform: uppercase;
                font-weight: bold;
                font-size: 16px;
                padding: 0;
                cursor: pointer;
                background-color: transparent;
            }
            .btn-text-barney {
                color: ${colors.barney};
            }
            .btn-sm {
                height: 32px;
            }

            .cursor-pointer {
                cursor: pointer;
            }

            p {
                margin: 0;
                font-family: 'Roboto';
                font-size: 14px;
                font-weight: normal;
            }

            .introduction-text {
                font-family: 'Montserrat';
                font-size: 18px;
            }
            .quote-text {
                font-family: 'Taviraj';
                font-size: 24px;
            }
            .small-text {
                font-size: 10px;
            }

            .large-title {
                font-family: 'Montserrat', sans-serif;
                font-size: 54px;
                line-height: 1.13;
                letter-spacing: -2.25px;
            }

            .light {
                color: ${colors.white};
            }

            /* Helper classes */

            .h-100 {
                height: 100%;
            }
            .w-100 {
                width: 100%;
            }

            .text-align-center {
                text-align: center;
            }

            .d-flex {
                display: flex;
            }
            .a-i-center {
                align-items: center;
            }

            /* Skeleton loaders */
            .skeleton-box {
                border-radius: 2px;
                background-color: ${colors.paleBlue};
            }
            .skeleton-box-dark {
                background-color: ${colors.brownGrey};
            }
            .skeleton-text-line {
                width: 100%;
                height: 10px;
            }
            .skeleton-text-line-short {
                width: 70%;
            }
            .skeleton-button {
                width: 134px;
                height: 42px;
                border-radius: 21px;
            }
        `}</style>
        <style jsx>{styles}</style>
    </>
);

export default App;
