import React from 'react';
import { Router } from '@reach/router';

import useAuth from 'hooks/useAuth';

import AdminMenu from './parts/AdminMenu';
import AdminSignIn from './parts/AdminSignIn';

import AdminHome from './subviews/AdminHome';
import CreateInvoice from './subviews/CreateInvoice';

const Admin = () => {
    const { user, initializing, signOut } = useAuth();

    if (initializing) return null;

    if (!initializing && user) {
        if (user.email.includes('@reallybadapps.com')) {
            return (
                <>
                    <div>
                        <AdminMenu />
                        <div>
                            <Router primary={false}>
                                <AdminHome path="/" />
                                <CreateInvoice path="create-invoice" />
                            </Router>
                        </div>
                    </div>
                </>
            );
        }
        signOut();
    }

    return <AdminSignIn />;
};

export default Admin;
