import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import { SERVICE_BASE_URL, API_KEY } from 'constants.js';

import ProductDisplay from './parts/ProductDisplay';

// test URL - http://localhost:3000/checkout/5f5ad5b5

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
    'pk_live_51HHHSSEfRVx9Pyc4hyXVD4iHmsmb6yByemp5ZLFmVDI5yEt4UJpgtHMy9XsiKnJoTTyDo1vxnxfhq09S1Lknw9L300slZhMUkn'
);

const CREATE_SESSION_URL = 'gcf_podcast_placement_stripe_session';

const Checkout = ({ invoiceId }) => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get('success')) {
            setMessage('Order placed! You will receive an email confirmation.');
        }
        if (query.get('canceled')) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    const handleCheckout = async () => {
        const stripe = await stripePromise;
        const response = await fetch(
            `${SERVICE_BASE_URL}/${CREATE_SESSION_URL}?api_key=${API_KEY}&invoice_id=${invoiceId}`,
            {
                method: 'POST',
            }
        );
        const session = await response.json();
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    };

    return (
        <div className="container pt-md">
            {message ? (
                <p>{message}</p>
            ) : (
                <ProductDisplay
                    onCheckout={handleCheckout}
                    invoiceId={invoiceId}
                />
            )}
        </div>
    );
};

export default Checkout;
