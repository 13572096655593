import React, { Fragment } from 'react';

import Tab from './parts/Tab';

const Tabs = ({ tabs, activeTab, onTabSelect }) => (
	<Fragment>
		<div className="tabs-container">
			{tabs.map(({ value, label }) => (
				<Tab tab={value} activeTab={activeTab} onTabSelect={onTabSelect} key={`tab-${value}`}>
					{label}
				</Tab>
			))}
		</div>
		<style jsx>{`
			.tabs-container {
				display: flex;
				height: 30px;
			}
		`}</style>
	</Fragment>
);

export default Tabs;
